import React from 'react';
import './App.scss';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './Routes';
import Theme from './Utils/Themes';

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ThemeProvider theme={Theme}>
        <AppRoutes />
      </ThemeProvider>
    </>
  );
}

export default App;
