import { IActionsDto } from 'Stores/Models/i-actions.dto';
import { IProductMainCategory } from 'Views/Categories/Models/i-product-main-category';

const CategoryList = (state: IProductMainCategory[] | null, action: IActionsDto<IProductMainCategory[]>): IProductMainCategory[] | null => {
  let stateClone = state;
  if (!stateClone) {
    stateClone = null;
  }
  switch (action.type) {
    case 'CATEGORYLIST':
      stateClone = action.data;
      return stateClone;
    default:
      return stateClone;
  }
};

export default CategoryList;
