import styled from 'styled-components';

export const Wrapper = styled.main`
  width: 100%;
  height: 100vh;
  background: ${(props) => props.theme.bgColor};
  overflow-x: hidden;
  overflow-y: auto;
`;

export const MainLayout = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 2rem 18.5rem 0rem 2rem;
  transition: all 500ms ease;
  @media only screen and (max-width: 991px) {
    padding-right: 1.5rem;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 1.5rem 0;
  min-height: calc(100vh - 9rem);
`;
