import { IActionsDto } from 'Stores/Models/i-actions.dto';
import { ITranslateDiscountCodeListDto } from 'Views/PublicDiscountCode/Models/i-translate-discount-code-list.dto';

const DiscountCodeReducer = (state: ITranslateDiscountCodeListDto | null, action: IActionsDto<ITranslateDiscountCodeListDto | null>): ITranslateDiscountCodeListDto | null => {
  let stateClone = state;
  if (!stateClone) {
    stateClone = null;
  }
  switch (action.type) {
    case 'DISCOUNTCODE':
      stateClone = action.data;
      return stateClone;
    default:
      return stateClone;
  }
};

export default DiscountCodeReducer;
