enum ServerErrorEnum {
  NotExpectedException = 0,
  VerificationCodeNotFound = 1,
  EmailAlreadyIsSubscriber = 2,
  ItemNotFound = 3,
  CaptchaCodeIsInvalid = 4,
  CommandValidationError = 5,
  AdminUserNameOrPasswordIsWrong = 6,
  UserNotFound = 7,
  CacheKeyNotFound = 8,
  RestartOperationPlease = 9,
  MobileAlreadyRegistered = 10,
  InvalidPassword = 11,
  UserPresenceIsAlreadyRegistered = 12,
  UserHasNotRegisteredForThisEvent = 13,
  UserHasPurchasedThisProduct = 14,
  DuplicateItem = 18,
}

export default ServerErrorEnum;
