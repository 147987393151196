import { ISendNewSMSStoreDto } from 'Views/SendSMS/Models/i-send-new-sms-store.dto';
import { IActionsDto } from '../Models/i-actions.dto';

const SendNewSMS = (state: ISendNewSMSStoreDto | null, action: IActionsDto<ISendNewSMSStoreDto>): ISendNewSMSStoreDto | null => {
  let stateClone = state;
  if (!stateClone) {
    stateClone = null;
  }
  switch (action.type) {
    case 'SENDNEWSMS':
      stateClone = action.data;
      return stateClone;
    default:
      return stateClone;
  }
};

export default SendNewSMS;
