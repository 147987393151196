import styled from 'styled-components';

export const TitleWrapper = styled.section`
  padding-bottom: 1.0rem;
  width: 100%;
  border-bottom: 2px solid ${(props) => props.theme.borderColor};


  @media only screen and (max-width: 991px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem 1rem;
    box-shadow: 0 3px 14px 0 rgba(0,0,0,0.16);
    border-radius: ${(props) => props.theme.radius3} ;
  }
`;

export const TitleItem = styled.h1`
  font-weight: 600;
  margin-bottom: 0;
  color: #3c3b37;
  font-size: 1.75rem;
  @media only screen and (max-width: 991px) {
   font-size: 1.5rem
  }
`;

export const MenuToggle = styled.button`
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 3px 0;
    border: none;
    background: transparent ;
    span {
      width: 18px;
      height: 3px;
      border-radius: 100px;
      background-color: #3D353C;
      
      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }
`;

export const TitleLogo = styled.div`
  height: 40px;
  img {
    display: block;
    max-height: 100%;
  }
`;
