import { IAssignEventStoreDto } from 'Views/AssignEvent/Models/i-assign-event-store.dto';
import { IActionsDto } from '../Models/i-actions.dto';

const AssignEventReducer = (state: IAssignEventStoreDto | null, action: IActionsDto<IAssignEventStoreDto | null>): IAssignEventStoreDto | null => {
  let stateClone = state;
  if (!stateClone) {
    stateClone = null;
  }
  switch (action.type) {
    case 'ASSIGNEVENT':
      stateClone = action.data;
      return stateClone;
    default:
      return stateClone;
  }
};

export default AssignEventReducer;
