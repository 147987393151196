import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Loading from 'Stores/Actions/loading';
import TokenService from 'Utils/Services/token-service';
import store from 'Stores/index';
import { IResponseModel } from 'Utils/Models/i-response.model';
import HandleError from './handle-errors';

class Interceptors {
  private serverUrl = `${process.env.REACT_APP_API_HOST}api/`;

  private AxiosInstance: AxiosInstance;

  get axiosInstance() {
    return this.AxiosInstance;
  }

  private store = store;

  private handleError = new HandleError();

  constructor() {
    this.AxiosInstance = axios.create({ baseURL: this.serverUrl });

    this.AxiosInstance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (!config.onUploadProgress) {
          this.store.dispatch(Loading(true));
        }
        const requestConfig = { ...config };
        requestConfig.headers!['Content-Type'] = 'application/json';
        return requestConfig;
      },
      (error: any) => {
        this.store.dispatch(Loading(false));
        return Promise.reject(error);
      },
    );

    this.AxiosInstance.interceptors.response.use(
      (response: AxiosResponse<IResponseModel<any>>) => {
        this.store.dispatch(Loading(false));
        if (response.status === 200 && !response.data.success) {
          this.handleError.handle(response.data.errorCode);
          return Promise.reject(response.data.errorCode);
        }
        return response;
      },
      (error: any) => {
        this.store.dispatch(Loading(false));
        if (error.response.status && (error.response.status === 401 || error.response.statuss === 403)) {
          TokenService.Logout();
        }
        if (error.response.status && (error.response.status !== 400 && error.response.status !== 404)) {
          this.handleError.handle(error.response);
        }
        if (error.response.status && error.response.status === 404) {
          window.location.replace('*');
        }
        return Promise.reject(error);
      },
    );
  }
}

export default Interceptors;
