import { IActionsDto } from '../Models/i-actions.dto';

const Title = (state: string | null, action: IActionsDto<string>): string | null => {
  let stateClone = state;
  if (!stateClone) {
    stateClone = null;
  }
  switch (action.type) {
    case 'MAINTITLE':
      stateClone = action.data;
      return stateClone;
    default:
      return stateClone;
  }
};

export default Title;
