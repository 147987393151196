import { IActionsDto } from 'Stores/Models/i-actions.dto';
import { IMentorSummaryList } from 'Views/Mentors/Models/i-mentor-summary-list';

const MentorsSummary = (state: IMentorSummaryList[] | null, action: IActionsDto<IMentorSummaryList[]>): IMentorSummaryList[] | null => {
  let stateClone = state;
  if (!stateClone) {
    stateClone = null;
  }
  switch (action.type) {
    case 'MENTORSSUMMARY':
      stateClone = action.data;
      return stateClone;
    default:
      return stateClone;
  }
};

export default MentorsSummary;
