// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useLayoutEffect, useState } from 'react';
import { DeviceModeType } from '../Enums/device-mode.type';

function useWindowSize() {
  const devicesWidth = {
    mobile: { min: 0, max: 767 },
    tablet: { min: 768, max: 991 },
    desktop: { min: 992 },
  };

  const resize = (size: number): DeviceModeType => {
    if (size <= devicesWidth.mobile.max) {
      return 'mobile';
    }
    if (size >= devicesWidth.tablet.min && size <= devicesWidth.tablet.max) {
      return 'tablet';
    }
    return 'desktop';
  };

  const [size, setSize] = useState<DeviceModeType>();
  useLayoutEffect(() => {
    setSize(resize(window.innerWidth));
    function updateSize() {
      setSize(resize(window.innerWidth));
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default useWindowSize;
