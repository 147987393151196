import { IActionsDto } from '../Models/i-actions.dto';

const EventEditMode = (state: boolean | null, action: IActionsDto<boolean>): boolean | null => {
  let stateClone = state;
  if (!stateClone) {
    stateClone = null;
  }
  switch (action.type) {
    case 'EVENTEDITMODE':
      stateClone = action.data;
      return stateClone;
    default:
      return stateClone;
  }
};

export default EventEditMode;
