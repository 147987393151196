import { Dispatch } from '@reduxjs/toolkit';
import { IActionsDto } from 'Stores/Models/i-actions.dto';
import { IBootstrapper } from 'Utils/Models/i-bootstrapper';
import { GetBootstrapper } from 'Utils/Services/bootstrapper-service';

export const BootstrapperAction = (data: IBootstrapper): IActionsDto<IBootstrapper> => ({
  type: 'BOOTSTRAPPERACTION',
  data,
});

export const GetBootstrapperInfo = (): any => (dispatch: Dispatch) => GetBootstrapper().then((res) => {
  dispatch(BootstrapperAction(res.result));
});
