import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import TokenService from 'Utils/Services/token-service';

function PublicRoute() {
  const hasToken = TokenService.getAccessToken();
  return !hasToken ? <Outlet /> : <Navigate to="*" />;
}

export default PublicRoute;
