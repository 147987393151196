/* eslint-disable @typescript-eslint/no-unused-vars */
import jwt_decode from 'jwt-decode';
import { RolesEnum } from 'Routes/Enums/roles.enum';
import TokenService from './token-service';

export function AccessLevelService(token: string, routePermission: string[] | null): boolean {
  const tokenObject: any = jwt_decode(token);
  const userPermissionLevel: RolesEnum = tokenObject[TokenService.tokenRole];
  if (userPermissionLevel === RolesEnum.Admin || routePermission === null) {
    return true;
  }
  return routePermission.includes(userPermissionLevel);
}
