import { ITranslatePaymentListDto } from 'Views/Financial/Models/i-translate-payment-list.dto';
import { IActionsDto } from '../Models/i-actions.dto';

const PaymentDetailReducer = (state: ITranslatePaymentListDto | null, action: IActionsDto<ITranslatePaymentListDto | null>): ITranslatePaymentListDto | null => {
  let stateClone = state;
  if (!stateClone) {
    stateClone = null;
  }
  switch (action.type) {
    case 'PAYMENTDETAIL':
      stateClone = action.data;
      return stateClone;
    default:
      return stateClone;
  }
};

export default PaymentDetailReducer;
