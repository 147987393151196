import React from 'react';
import Logo from 'Assets/images/logo.png';
import { LoadingBox, LoadingLogo } from './styles';

function LoadingWrapper() {
  return (
    <LoadingBox>
      <LoadingLogo>
        <img src={Logo} alt="loading" />
      </LoadingLogo>
    </LoadingBox>
  );
}

export default LoadingWrapper;
