/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Suspense, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import LoadingWrapper from 'Components/Shared/LoadingWrapper';
import MainTitle from 'Components/Shared/MainTitle';
import Sidebar from 'Components/Shared/Sidebar';
import TokenService from 'Utils/Services/token-service';
import { GetBootstrapperInfo } from 'Stores/Actions/bootstrapper.action';
import { IPrivateRoutePropsDto } from 'Routes/Models/i-private-route-props.dto';
import jwt_decode from 'jwt-decode';
import { AccessLevelService } from 'Utils/Services/access-level-service';
import {
  ContentWrapper,
  MainLayout,
  Wrapper,
} from './styles';

function PrivateRoute(props: IPrivateRoutePropsDto) {
  const {
    loadingFlag,
    roles = null,
  } = props;

  const isAuth = TokenService.getAccessToken();
  const dispatch = useDispatch();
  useEffect(() => {
    if (TokenService.getAccessToken()) {
      dispatch(GetBootstrapperInfo());
    }
  }, []);
  return (
    isAuth
      ? (
        AccessLevelService(TokenService.getAccessToken()!, roles) ? (
          <Wrapper>
            <MainLayout>
              <Sidebar />
              <MainTitle />
              <ContentWrapper>
                <Suspense>
                  {loadingFlag && <LoadingWrapper />}
                  <Outlet />
                </Suspense>
              </ContentWrapper>
            </MainLayout>
          </Wrapper>
        ) : <Navigate to="dashboard" />

      )
      : <Navigate to="login" />
  );
}
function mapStateToProps(state: any) {
  const { Loading } = state;
  return { loadingFlag: Loading };
}
export default connect(mapStateToProps)(PrivateRoute);
