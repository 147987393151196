import styled, { keyframes } from 'styled-components';

const breatheAnimation = keyframes`
  0% {
      transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const LoadingBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255,255,255,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  border-radius: ${(props) => props.theme.radius3};
`;

export const LoadingLogo = styled.div`
  animation-name: ${breatheAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;
