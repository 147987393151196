import { IActionsDto } from 'Stores/Models/i-actions.dto';
import { IBootstrapper } from 'Utils/Models/i-bootstrapper';

const BootstrapperReducer = (state: IBootstrapper | null, action: IActionsDto<IBootstrapper>): IBootstrapper | null => {
  let stateClone = state;
  if (!stateClone) {
    stateClone = null;
  }
  switch (action.type) {
    case 'BOOTSTRAPPERACTION':
      stateClone = action.data;
      return stateClone;
    default:
      return stateClone;
  }
};

export default BootstrapperReducer;
