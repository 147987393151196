import { combineReducers } from 'redux';
import AssignEventEditModeReducer from './assign-event-edit-mode.reducer';
import AssignEventReducer from './assign-event.reducer';
import BootstrapperReducer from './bootstrapper.reducer';
import CategoryList from './category-list';
import DiscountCodeReducer from './discount-code.reducer';
import EventEditMode from './event-edit-mode';
import Loading from './loading';
import MentorsSummary from './mentors-summary';
import MenuToggle from './menu-toggle';
import PaymentDetailReducer from './payment-detail.reducer';
import ProgressBar from './progress-bar';
import SendNewSMS from './send-new-sms';
import SendSMSEditMode from './send-sms-edit-mode';
import Title from './title';

const reducers = combineReducers({
  Loading,
  Title,
  MenuToggle,
  ProgressBar,
  MentorsSummary,
  CategoryList,
  EventEditMode,
  SendNewSMS,
  SendSMSEditMode,
  AssignEventReducer,
  BootstrapperReducer,
  AssignEventEditModeReducer,
  DiscountCodeReducer,
  PaymentDetailReducer,
});

export default reducers;
