import React, { SyntheticEvent, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import TokenService from 'Utils/Services/token-service';
import {
  Link, NavLink,
} from 'react-router-dom';
import MenuHandler from 'Stores/Actions/menu-toggle';
import useWindowSize from 'Utils/Hooks/window-size';
import logo from 'Assets/images/white-logo.png';
import { RolesEnum } from 'Routes/Enums/roles.enum';
import { AccessLevelService } from 'Utils/Services/access-level-service';
import {
  AsideBody,
  AsideHeader,
  AsideNav,
  AsideOverlay,
  AsideWrapper,
  FixedNav,
  NavButton,
  NavIcon,
  Navitem,
  NavItemChildrenItem,
  NavItemChildrenWrapper,
  NavTitle,
} from './styles';
import { INavListDto } from './Models/i-nav-list.dto';
import ISidebarPropsDto from './Models/i-sidebar-props.dto';

function Sidebar({ menuToggle }: ISidebarPropsDto) {
  const device = useWindowSize();
  const dispatch = useDispatch();
  const NavList: INavListDto[] = [
    {
      title: 'صفحه اصلی',
      icon: 'far fa-home',
      link: '/dashboard',
      permission: null,
    },
    {
      title: 'رویدادها',
      icon: 'far fa-podium',
      link: '/events',
      permission: null,
    },
    {
      title: 'تیکت',
      icon: 'far fa-headset',
      link: '/tickets',
      permission: null,
    },
    {
      title: 'مدرسین',
      icon: 'far fa-chalkboard-teacher',
      link: '/mentors',
      permission: null,
      children: [
        {
          title: 'لیست مدرسین',
          link: '/mentors/list',
          permission: null,
        },
        {
          title: 'مدرسین محبوب',
          link: '/mentors/popular',
          permission: null,
        },
      ],
    },
    {
      title: 'کاربران',
      icon: 'far fa-users',
      link: '/users',
      permission: null,
      children: [
        {
          title: 'لیست کاربران',
          link: '/users',
          permission: null,
        },
        {
          title: 'تخصیص رویداد به کاربر',
          link: '/assign-event',
          permission: null,
        },
      ],
    },
    {
      title: 'ارسال پیامک',
      icon: 'far fa-sms',
      link: '/sms',
      permission: null,
    },
    {
      title: 'پرسش و پاسخ ها',
      icon: 'far fa-question',
      link: '/qa',
      permission: null,
    },
    {
      title: 'نظرات',
      icon: 'far fa-comment',
      link: '/comments',
      permission: null,
    },
    {
      title: 'مدیریت بنرهای صفحه اصلی',
      icon: 'far fa-presentation',
      link: '/banner-managment',
      permission: null,
    },
    {
      title: 'کد تخفیف',
      icon: 'far fa-percent',
      link: '/discount-code',
      permission: null,
    },
    {
      title: 'مالی',
      icon: 'far fa-money-bill',
      link: '/financial',
      permission: [RolesEnum.Admin],
    },
    {
      title: 'خبرنامه',
      icon: 'far fa-newspaper',
      link: '/newsletter',
      permission: null,
    },
    {
      title: 'لیست درخواست همکاری',
      icon: 'far fa-id-card-alt',
      link: '/cooperation-request',
      permission: null,
    },
  ];

  const closeMenu = () => {
    dispatch(MenuHandler());
  };
  const removeClass = (event: SyntheticEvent) => {
    const m = document.getElementsByClassName('expanded');
    Array.prototype.forEach.call(m, (el) => {
      if (el !== event.currentTarget) {
        if (el.children[1]) {
          el.classList.remove('expanded', 'active');
        }
      }
    });
  };

  const expandedMenu = (event: SyntheticEvent) => {
    event.stopPropagation();
    removeClass(event);
    if (event.currentTarget.classList.value.includes('expanded') && event.currentTarget.classList.value.includes('active')) {
      event.currentTarget.closest('li.parent')!.classList.remove('expanded', 'active');
    } else {
      event.currentTarget.closest('li.parent')!.classList.add('expanded', 'active');
    }
  };

  useEffect(() => {
    if (device !== 'desktop' && menuToggle) {
      dispatch(MenuHandler());
    }
  }, [device]);

  return (
    <>
      {(device !== 'desktop' && menuToggle) && <AsideOverlay onClick={() => closeMenu()} />}
      <AsideWrapper className={menuToggle ? 'isOpen' : ''}>
        <AsideHeader>
          <Link to="/dashboard">
            <img src={logo} alt="logo" />
          </Link>
        </AsideHeader>
        <AsideBody>
          <AsideNav>
            {
              NavList.map((m: INavListDto) => (
                (m.children && m.children.length > 0) ? (
                  AccessLevelService(TokenService.getAccessToken()!, m.permission) && (
                    <Navitem
                      key={m.title}
                      onClick={(e) => expandedMenu(e)}
                      className="parent"
                    >
                      <div>
                        <NavIcon className={m.icon} />
                        <NavTitle className="parent-title">
                          {m.title}
                          <i className="far fa-angle-left" />
                        </NavTitle>
                      </div>
                      <NavItemChildrenWrapper>
                        {m.children.map((child) => (
                          AccessLevelService(TokenService.getAccessToken()!, child.permission) && (
                            <NavItemChildrenItem
                              key={child.title}
                              onClick={(e) => {
                                e.stopPropagation();
                                dispatch(MenuHandler());
                              }}
                            >
                              <NavLink
                                to={child.link}
                              >
                                <span>{child.title}</span>
                              </NavLink>
                            </NavItemChildrenItem>
                          )
                        ))}
                      </NavItemChildrenWrapper>
                    </Navitem>
                  )
                ) : (
                  AccessLevelService(TokenService.getAccessToken()!, m.permission) && (
                    <Navitem
                      key={m.title}
                      onClick={(e) => {
                        removeClass(e);
                        dispatch(MenuHandler());
                      }}
                    >
                      <NavLink
                        to={m.link}
                        className={({ isActive }: any) => (isActive ? 'active-route' : undefined)}
                      >
                        <NavIcon className={m.icon} />
                        <NavTitle>{m.title}</NavTitle>
                      </NavLink>
                    </Navitem>
                  )
                )
              ))
            }
          </AsideNav>
          <FixedNav>
            <Navitem>
              <NavButton onClick={() => TokenService.Logout()}>
                <NavIcon className="far fa-sign-out" />
                <NavTitle>خروج</NavTitle>
              </NavButton>
            </Navitem>
          </FixedNav>
        </AsideBody>
      </AsideWrapper>
    </>
  );
}

function mapStateToProps(state: any) {
  const { MenuToggle } = state;
  return { menuToggle: MenuToggle };
}
export default connect(mapStateToProps)(Sidebar);
