import { toast } from 'react-toastify';
import ServerErrorEnum from '../Enums/server-error.enum';
import i18next from '../../i18n';

class HandleError {
  private Unauthorized!: boolean;

  private Forbidden!: boolean;

  handle(error: any) {
    let errorCode: number | null = null;
    let errorType = '';
    let authError = false;

    if (error && error.status) {
      authError = error.status === 401 || error.status === 403;
      this.Unauthorized = error.status === 401;
      this.Forbidden = error.status === 403;
    } else {
      errorType = 'knownError';
      errorCode = error;
    }

    if (authError) {
      errorType = 'auth';
    } else if (error.error) {
      if (error.error.ErrorCode) {
        errorType = 'knownError';
        errorCode = error.error.ErrorCode;
      } else if (error.error.title) {
        errorType = 'error';
        errorCode = null;
      } else {
        errorType = 'unknownError';
        errorCode = 0;
      }
    }

    let serverError: string;
    switch (errorType) {
      case 'knownError':
        serverError = ServerErrorEnum[errorCode!];
        toast.error(i18next.t(serverError).toString());
        break;
      case 'error':
        toast.error(error.error.title);
        break;
      case 'auth':
        toast.error(i18next.t('LoginAgain').toString());
        break;
      default:
        toast.error(i18next.t('UnknownError').toString());
        break;
    }
  }
}

export default HandleError;
