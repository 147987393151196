import Cookies from 'js-cookie';

class TokenService {
  static tokenRole = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

  static setAccessToken(value: string) {
    Cookies.set(process.env.REACT_APP_ACCESS_TOKEN_KEY_NAME!, value);
  }

  static setRefreshToken(value: string) {
    Cookies.set(process.env.REACT_APP_REFRESH_TOKEN_KEY_NAME!, value);
  }

  static getRefreshToken() {
    return Cookies.get(process.env.REACT_APP_REFRESH_TOKEN_KEY_NAME!);
  }

  static getAccessToken() {
    return Cookies.get(process.env.REACT_APP_ACCESS_TOKEN_KEY_NAME!);
  }

  static Logout() {
    Cookies.remove(process.env.REACT_APP_ACCESS_TOKEN_KEY_NAME!);
    Cookies.remove(process.env.REACT_APP_REFRESH_TOKEN_KEY_NAME!);
    window.location.replace('/');
  }
}

export default TokenService;
