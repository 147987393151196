import { AxiosInstance, AxiosResponse } from 'axios';
import queryString from 'query-string';
import { IResponseModel } from 'Utils/Models/i-response.model';
import TokenService from 'Utils/Services/token-service';
import Interceptors from './Services/instance';

class ApiService {
  axiosInstance: AxiosInstance = new Interceptors().axiosInstance;

  Get(url: string, params?: any, needAuth = true): Promise<IResponseModel<any>> {
    const headers: any = {
      'Content-Type': 'application/json',
    };

    if (needAuth) {
      headers.Authorization = `Bearer ${TokenService.getAccessToken()}`;
    }
    return this.axiosInstance({
      method: 'get',
      url,
      headers,
      params,
      paramsSerializer: (paramList: AxiosResponse<IResponseModel<any>>): string => queryString.stringify(paramList),
    }).then((res: any) => res.data);
  }

  Post<T>(url: string, payload: T, needAuth = true, onUploadProgress?: any): Promise<IResponseModel<any>> {
    const headers: any = {
      'Content-Type': 'application/json',
    };

    if (needAuth) {
      headers.Authorization = `Bearer ${TokenService.getAccessToken()}`;
    }
    return this.axiosInstance({
      method: 'post',
      data: payload,
      headers,
      url,
      onUploadProgress,
    }).then((res: AxiosResponse<IResponseModel<any>>) => res.data);
  }

  Put<T>(url: string, payload?: T, needAuth = true, onUploadProgress?: any): Promise<IResponseModel<any>> {
    const headers: any = {
      'Content-Type': 'application/json',
    };

    if (needAuth) {
      headers.Authorization = `Bearer ${TokenService.getAccessToken()}`;
    }
    return this.axiosInstance({
      method: 'put',
      data: payload,
      headers,
      url,
      onUploadProgress,
    }).then((res: AxiosResponse<IResponseModel<any>>) => res.data);
  }

  Patch<T>(url: string, payload?: T, needAuth = true): Promise<IResponseModel<any>> {
    const headers: any = {
      'Content-Type': 'application/json',
    };

    if (needAuth) {
      headers.Authorization = `Bearer ${TokenService.getAccessToken()}`;
    }
    return this.axiosInstance({
      method: 'patch',
      data: payload,
      headers,
      url,
    }).then((res: AxiosResponse<IResponseModel<any>>) => res.data);
  }

  Delete(url: string, payload?: any, needAuth = true): Promise<IResponseModel<any>> {
    const headers: any = {
      'Content-Type': 'application/json',
    };

    if (needAuth) {
      headers.Authorization = `Bearer ${TokenService.getAccessToken()}`;
    }
    return this.axiosInstance({
      method: 'delete',
      data: payload,
      headers,
      url,
    }).then((res: AxiosResponse<IResponseModel<any>>) => res.data);
  }
}

export default new ApiService();
