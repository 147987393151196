import styled from 'styled-components';

export const AsideOverlay = styled.span`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(132, 115, 129, 0.81);
  z-index: 100;
`;

export const AsideWrapper = styled.aside`
  width: 260px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  border-top-left-radius:${(props) => props.theme.radius3} ;
  border-bottom-left-radius:${(props) => props.theme.radius3} ;
  background-color: ${(props) => props.theme.navyBlue1} ;
  z-index: 101;
  transition: all 500ms ease;
  @media only screen and (max-width: 991px) {
    right: -260px;
    &.isOpen {
      right: 0;
    }
  }
`;

export const AsideHeader = styled.div`
  width: 100%;
  height: 8rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column ;

  .today-date {
    color: ${(props) => props.theme.white} ;
  }
`;

export const AsideBody = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  
  width: 100%;
  height: calc(100vh - 8rem);
  padding-bottom: 2rem;
`;

export const AsideNav = styled.ul`
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 250px);

    scrollbar-color: ${(props) => props.theme.$navyBlue1} ${(props) => props.theme.navyBlue1};
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-track {
      background-color: ${(props) => props.theme.navyBlue1};
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.navyBlue1};
    }
`;

export const Navitem = styled.li`
  position: relative;
  
  &.parent {
   &.expanded , &.active {
    > div{
      span {
        color: ${(props) => props.theme.yellow1};
        &.parent-title {
          i {
            transform: rotate(-90deg);
          }
        }
      }

      &:after {
        width: 0.5rem;
      }
    }
    > ul {
      transition: max-height 300ms ease;
      max-height: 280px;
    }
   }
  }

  >a, >button, >div {
    width: 100%;
    height: 40px;

    display: flex;
    align-items: center;
    
    color: ${(props) => props.theme.white};
    text-decoration: none;

    padding-right: 1rem;

    transition: all 500ms ease;
    cursor: pointer;

    span {
      color: ${(props) => props.theme.white};
    }

    &:after {
      content: ' ';
      height: 40px;
      width: 0;
      background-color: ${(props) => props.theme.yellow1};
      position: absolute;
      top: 0;
      left: 0;
      border-top-right-radius :${(props) => props.theme.radius2};
      border-bottom-right-radius :${(props) => props.theme.radius2};
      transition: all 500ms ease;
    }

    &:hover ,&.active-route {
      span {
        color: ${(props) => props.theme.yellow1};
      }

      &:after {
        width: 0.5rem;
      }
    }
  }
`;

export const NavIcon = styled.i`
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
`;

export const NavTitle = styled.span`
  width: calc(100% - 3rem);

  &.parent-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.75rem;
    i {
      transition: all 300ms ease;
      font-size: 1.25rem;
    }
  }
`;

export const FixedNav = styled.ul`
  
`;

export const NavButton = styled.button`
    background-color: transparent;

    text-align: right;
    border: none;
`;

export const NavItemChildrenWrapper = styled.ul`
  padding-right: 2rem;
  max-height: 0;
  transition: max-height 300ms ease;
  overflow: hidden;
`;

export const NavItemChildrenItem = styled.li`
  margin:0.5rem 0;

  a,button {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${(props) => props.theme.white};
    text-decoration: none;
    border: 0;
    &:before {
      content: ' ';
      width: 8px;
      height: 2px;
      background: ${(props) => props.theme.white};
      border-radius: ${(props) => props.theme.radius2};
      margin-left: 0.5rem;
    }

    span {
      color: ${(props) => props.theme.white};
      font-size:0.875rem
    }

    &:hover ,&.active {
      span {
        color: ${(props) => props.theme.yellow1};
      }
    }
  }
   
`;
