const MenuToggle = (state: boolean, action: any): boolean => {
  let stateClone = state;
  if (!stateClone) {
    stateClone = false;
  }
  switch (action.type) {
    case 'MENUTOGGLE':
      stateClone = stateClone !== null ? !stateClone : true;
      return stateClone;
    default:
      return stateClone;
  }
};

export default MenuToggle;
