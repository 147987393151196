import React from 'react';
import { ThemeProvider } from 'react-bootstrap';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './i18n';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import store from './Stores/index';
import { IConfigDto } from './Utils/Models/i-config.dto';

declare global {
  interface Window {
    config: IConfigDto;
  }
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const storeProvider = store;

root.render(
  <React.StrictMode>
    <Provider store={storeProvider}>
      <ThemeProvider dir="rtl">
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
