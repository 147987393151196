import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useWindowSize from 'Utils/Hooks/window-size';
import MenuHandler from 'Stores/Actions/menu-toggle';
import logo from 'Assets/images/mobile-logo.png';
import IMainTitlePropsDto from './Models/i-main-title-props.dto';
import {
  TitleWrapper,
  TitleItem,
  MenuToggle,
  TitleLogo,
} from './styles';

function MainTitle({ title }: IMainTitlePropsDto) {
  const device = useWindowSize();
  const dispatch = useDispatch();
  const menuOpen = () => {
    dispatch(MenuHandler());
  };

  return (
    <TitleWrapper>
      {device !== 'desktop' && (
        <MenuToggle onClick={() => menuOpen()}>
          <span />
          <span />
          <span />
        </MenuToggle>
      )}

      <TitleItem>
        {title}
      </TitleItem>
      {device !== 'desktop' && (
        <TitleLogo>
          <Link to="/dashboard">
            <img src={logo} alt="logo" />
          </Link>
        </TitleLogo>
      )}

    </TitleWrapper>
  );
}

function mapStateToProps(state: any) {
  const { Title } = state;
  return { title: Title };
}
export default connect(mapStateToProps)(MainTitle);
