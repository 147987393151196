const Theme = {
  black: '#000000',
  white: '#ffffff',

  bgColor: '#fafafa',
  textColor: '#3c3b37',
  borderColor: '#dcdcdc',

  bgSearch: 'rgba(226, 237, 238, 0.3)',
  pink: '#f2cecd',
  purple: '#7e0d9f',
  error: '#ff6f59',

  navyBlue1: '#022834',
  navyBlue2: '#032e3b',
  navyBlue3: '#1f4550',

  deepOrange1: '#f38c09',
  deepOrange2: '#f5a53f',
  deepOrange3: '#f7b25b',
  deepOrange4: '#fce5c8',
  deepOrange5: '#fdf2e3',

  yellow1: '#fac600',
  yellow2: '#fbd955',
  yellow3: '#fce58d',
  yellow4: '#fdf2c6',
  yellow5: '#fef8e2',
  yellow6: '#ffd43f',

  teal1: '#006861',
  teal2: '#026567',
  teal3: '#1e7677',
  teal4: '#3a8788',
  teal5: '#e2edee',

  blue1: '#1cbbe8',
  blue2: '#38c3eb',
  blue3: '#55ccee',
  blue4: '#b3e8f7',
  blue5: '#d9f3fb',
  blue6: '#e4fcff',
  blue7: '#14ebff',

  red1: '#c72622',
  red2: '#e02b27',
  red3: '#f2cecd',
  red4: '#f9e8e7',

  grey1: '#acacac',
  grey2: '#c8cdd2',
  grey3: '#8e8e8e',
  grey4: '#f2f4f7',
  grey5: '#cacaca',
  grey6: '#e2e7ed',
  grey7: '#707070',
  grey8: '#212121',

  radius1: '4px',
  radius2: '8px',
  radius3: '14px',
  radius100: '100px',
};

export default Theme;
