import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import PrivateRoute from './Components/private-route';
import PublicRoute from './Components/public-route';
import { RolesEnum } from './Enums/roles.enum';

const Login = React.lazy(() => import('Views/Login'));
const Dashboard = React.lazy(() => import('Views/Dashboard'));

const Tickets = React.lazy(() => import('Views/Tickets'));
const TicketList = React.lazy(() => import('Views/Tickets/Components/TicketsList'));
const TicketView = React.lazy(() => import('Views/Tickets/Components/TicketView'));

const Events = React.lazy(() => import('Views/Events'));
const EventsList = React.lazy(() => import('Views/Events/Components/EventsList'));
const SelectEventType = React.lazy(() => import('Views/Events/Components/SelectEventType'));

const CreateOrModifyEvent = React.lazy(() => import('Views/Events/Components/CreateorModifyEvent'));
const EventInformation = React.lazy(() => import('Views/Events/Components/CreateorModifyEvent/EventInformation'));
const ChooseMentor = React.lazy(() => import('Views/Events/Components/CreateorModifyEvent/ChooseMentor'));
const EventDetails = React.lazy(() => import('Views/Events/Components/CreateorModifyEvent/EventDetails'));
const EpisodesDetail = React.lazy(() => import('Views/Events/Components/CreateorModifyEvent/EpisodesDetail'));
const CreateTickets = React.lazy(() => import('Views/Events/Components/CreateorModifyEvent/CreateTickets'));
const OnlineTickets = React.lazy(() => import('Views/Events/Components/CreateorModifyEvent/CreateTickets/OnlineTickets'));
const OfflineTicket = React.lazy(() => import('Views/Events/Components/CreateorModifyEvent/CreateTickets/OfflineTicket'));
const EventsFaqs = React.lazy(() => import('Views/Events/Components/CreateorModifyEvent/EventsFaqs'));
const EventLocation = React.lazy(() => import('Views/Events/Components/CreateorModifyEvent/EventLocation'));
const EventPreview = React.lazy(() => import('Views/Events/Components/CreateorModifyEvent/EventPreview'));

const EventDetail = React.lazy(() => import('Views/Events/Components/EventDetail'));
const EventSummary = React.lazy(() => import('Views/Events/Components/EventDetail/EventSummary'));
const EventStudentsList = React.lazy(() => import('Views/Events/Components/EventDetail/EventStudentsList'));
const UploadEpisodes = React.lazy(() => import('Views/Events/Components/EventDetail/UploadEpisodes'));
const InPersonAttendance = React.lazy(() => import('Views/Events/Components/EventDetail/InPersonAttendance'));
const AttendanceLogList = React.lazy(() => import('Views/Events/Components/EventDetail/AttendanceLogList'));
const GeoLocation = React.lazy(() => import('Views/Events/Components/EventDetail/GeoLocation'));
const Sponsor = React.lazy(() => import('Views/Events/Components/EventDetail/Sponsor'));
const Attachments = React.lazy(() => import('Views/Events/Components/EventDetail/Attachments'));
const Certificate = React.lazy(() => import('Views/Events/Components/EventDetail/Certificate'));
const Followers = React.lazy(() => import('Views/Events/Components/EventDetail/Followers'));
const PreRegistrationList = React.lazy(() => import('Views/Events/Components/EventDetail/PreRegistrationList'));

const Mentors = React.lazy(() => import('Views/Mentors'));
const MentorList = React.lazy(() => import('Views/Mentors/Component/MentorList'));
const SingleMentor = React.lazy(() => import('Views/Mentors/Component/SingleMentor'));
const PolularMentor = React.lazy(() => import('Views/Mentors/Component/PopularMentor'));

const Users = React.lazy(() => import('Views/Users'));
const UsersList = React.lazy(() => import('Views/Users/Components/UsersList'));
const UserDetail = React.lazy(() => import('Views/Users/Components/UserDetail'));
const UserProfile = React.lazy(() => import('Views/Users/Components/UserDetail/UserProfile'));
const UserEvents = React.lazy(() => import('Views/Users/Components/UserDetail/UserEvents'));

const QuestionAnswer = React.lazy(() => import('Views/QuestionAnswer'));
const QuestionAnswerList = React.lazy(() => import('Views/QuestionAnswer/Components/QuestionAnswerList'));

const Comments = React.lazy(() => import('Views/Comments'));
const CommentsList = React.lazy(() => import('Views/Comments/Components/CommentsList'));

const SendSMS = React.lazy(() => import('Views/SendSMS'));
const SMSList = React.lazy(() => import('Views/SendSMS/Components/SMSList'));
const NewSMS = React.lazy(() => import('Views/SendSMS/Components/NewSMS'));
const SelectEvents = React.lazy(() => import('Views/SendSMS/Components/NewSMS/SelectEvents'));
const ModifySettings = React.lazy(() => import('Views/SendSMS/Components/NewSMS/ModifySettings'));
const MessageText = React.lazy(() => import('Views/SendSMS/Components/NewSMS/MessageText'));
const DefaultParameters = React.lazy(() => import('Views/SendSMS/Components/NewSMS/DefaultParameters'));
const NewSMSPreview = React.lazy(() => import('Views/SendSMS/Components/NewSMS/NewSMSPreview'));

const AssignEvent = React.lazy(() => import('Views/AssignEvent'));
const UserSelection = React.lazy(() => import('Views/AssignEvent/Components/UserSelection'));
const EventSelection = React.lazy(() => import('Views/AssignEvent/Components/EventSelection'));
const TicketSelection = React.lazy(() => import('Views/AssignEvent/Components/TicketSelection'));
const PriceDetail = React.lazy(() => import('Views/AssignEvent/Components/PriceDetail'));
const HomeBannersManagment = React.lazy(() => import('Views/HomeBannersManagment'));
const HomeBannerManagmentItems = React.lazy(() => import('Views/HomeBannersManagment/Components/HomeBannerManagmentItems'));
const AssignEventPreview = React.lazy(() => import('Views/AssignEvent/Components/AssignEventPreview'));

const PublicDiscountCode = React.lazy(() => import('Views/PublicDiscountCode'));
const DiscountCodeList = React.lazy(() => import('Views/PublicDiscountCode/Components/DiscountCodeList'));
const CreateAndEditDiscountCode = React.lazy(() => import('Views/PublicDiscountCode/Components/CreateAndEditDiscountCode'));

const Financial = React.lazy(() => import('Views/Financial'));
const PaymentList = React.lazy(() => import('Views/Financial/Components/PaymentList'));
const PaymentDetail = React.lazy(() => import('Views/Financial/Components/PaymentDetail'));

const Newsletter = React.lazy(() => import('Views/Newsletter'));
const NewsletterItems = React.lazy(() => import('Views/Newsletter/Components/NewsletterList'));

const CooperationRequest = React.lazy(() => import('Views/CooperationRequest'));
const CooperationRequestList = React.lazy(() => import('Views/CooperationRequest/Components/CooperationRequestList'));

const PageNotFound = React.lazy(() => import('Views/PageNotFound'));

function AppRoutes() {
  return (
    <div className="app-container">
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<PublicRoute />}>
            <Route index element={<Login />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route index element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="tickets" element={<Tickets />}>
              <Route index element={<Navigate to="list" replace />} />
              <Route path="list" element={<TicketList />} />
              <Route path="view/:ticketId" element={<TicketView />} />
            </Route>
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="events" element={<Events />}>
              <Route index element={<Navigate to="list" replace />} />
              <Route path="list" element={<EventsList />} />
              <Route path="select" element={<SelectEventType />} />
              <Route path="create" element={<CreateOrModifyEvent />}>
                <Route index element={<Navigate to="information" replace />} />
                <Route path="information" element={<EventInformation />} />
                <Route path="mentor" element={<ChooseMentor />} />
                <Route path="details" element={<EventDetails />} />
                <Route path="episodes-detail" element={<EpisodesDetail />} />
                <Route path="create-tickets" element={<CreateTickets />} />
                <Route path="faqs" element={<EventsFaqs />} />
                <Route path="location" element={<EventLocation />} />
                <Route path="preview" element={<EventPreview />} />
              </Route>
              <Route path="edit/:eventId" element={<CreateOrModifyEvent />}>
                <Route index element={<Navigate to="information" replace />} />
                <Route path="information" element={<EventInformation />} />
                <Route path="mentor" element={<ChooseMentor />} />
                <Route path="details" element={<EventDetails />} />
                <Route path="episodes-detail" element={<EpisodesDetail />} />
                <Route path="online-tickets" element={<OnlineTickets />} />
                <Route path="offline-tickets" element={<OfflineTicket />} />
                <Route path="faqs" element={<EventsFaqs />} />
                <Route path="location" element={<EventLocation />} />
                <Route path="preview" element={<EventPreview />} />
              </Route>

              <Route path="detail/:eventId" element={<EventDetail />}>
                <Route index element={<Navigate to="summary" replace />} />
                <Route path="summary" element={<EventSummary />} />
                <Route path="student" element={<EventStudentsList />} />
                <Route path="upload-episodes" element={<UploadEpisodes />} />
                <Route path="confirm-attendance" element={<InPersonAttendance />} />
                <Route path="attendance-log" element={<AttendanceLogList />} />
                <Route path="qa" element={<QuestionAnswerList />} />
                <Route path="comments-list" element={<CommentsList />} />
                <Route path="location" element={<GeoLocation />} />
                <Route path="sponsor" element={<Sponsor />} />
                <Route path="attachments" element={<Attachments />} />
                <Route path="certificate" element={<Certificate />} />
                <Route path="followers" element={<Followers />} />
                <Route path="pre-registration" element={<PreRegistrationList />} />
              </Route>
            </Route>
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="mentors" element={<Mentors />}>
              <Route index element={<Navigate to="list" replace />} />
              <Route path="list" element={<MentorList />} />
              <Route path="edit/:mentorId" element={<SingleMentor />} />
              <Route path="create" element={<SingleMentor />} />
              <Route path="popular" element={<PolularMentor />} />
            </Route>
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="users" element={<Users />}>
              <Route index element={<Navigate to="list" replace />} />
              <Route path="list" element={<UsersList />} />
              <Route path="detail/:userId" element={<UserDetail />}>
                <Route index element={<Navigate to="profile" replace />} />
                <Route path="profile" element={<UserProfile />} />
                <Route path="events" element={<UserEvents />} />
              </Route>
            </Route>
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="qa" element={<QuestionAnswer />}>
              <Route index element={<Navigate to="list" replace />} />
              <Route path="list" element={<QuestionAnswerList />} />
            </Route>
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="comments" element={<Comments />}>
              <Route index element={<Navigate to="list" replace />} />
              <Route path="list" element={<CommentsList />} />
            </Route>
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="sms" element={<SendSMS />}>
              <Route index element={<Navigate to="list" replace />} />
              <Route path="list" element={<SMSList />} />
              <Route path="new" element={<NewSMS />}>
                <Route index element={<Navigate to="events" replace />} />
                <Route path="events" element={<SelectEvents />} />
                <Route path="settings" element={<ModifySettings />} />
                <Route path="message-text" element={<MessageText />} />
                <Route path="parameters" element={<DefaultParameters />} />
                <Route path="preview" element={<NewSMSPreview />} />
              </Route>
            </Route>
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="assign-event" element={<AssignEvent />}>
              <Route index element={<Navigate to="user" replace />} />
              <Route path="user" element={<UserSelection />} />
              <Route path="events" element={<EventSelection />} />
              <Route path="tickets" element={<TicketSelection />} />
              <Route path="price-detail" element={<PriceDetail />} />
              <Route path="preview" element={<AssignEventPreview />} />
            </Route>
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="banner-managment" element={<HomeBannersManagment />}>
              <Route index element={<HomeBannerManagmentItems />} />
            </Route>
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="discount-code" element={<PublicDiscountCode />}>
              <Route index element={<Navigate to="list" replace />} />
              <Route path="list" element={<DiscountCodeList />} />
              <Route path="create" element={<CreateAndEditDiscountCode />} />
              <Route path="edit" element={<CreateAndEditDiscountCode />} />
            </Route>
          </Route>
          <Route element={<PrivateRoute roles={[RolesEnum.Admin]} />}>
            <Route path="financial" element={<Financial />}>
              <Route index element={<Navigate to="list" replace />} />
              <Route path="list" element={<PaymentList />} />
              <Route path="detail" element={<PaymentDetail />} />
            </Route>
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="newsletter" element={<Newsletter />}>
              <Route index element={<NewsletterItems />} />
            </Route>
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="cooperation-request" element={<CooperationRequest />}>
              <Route index element={<CooperationRequestList />} />
            </Route>
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AppRoutes;
